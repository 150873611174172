.community-header {
  color: white;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 15px;
}
.title {
  padding-top: 25px;
}
div .wrapper {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.telegram {
  vertical-align: middle;
  width: 15px;
  height: 15px;
  fill: white;
  filter: brightness(0) invert(1);
}
.disclaimer {
  font-size: 11px;
  text-align: center;
  margin-top: 10px;
  padding-left: 25%;
  padding-right: 25%;
}
div.input-container {
  width: 330px;
  padding-bottom: 15px;
}
@media only screen and (max-width: 600px) {
  .disclaimer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .community-header {
    font-size: 0.75rem;
  }
}
main {
  padding: 4.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: max-content;
  /* height: 100% */
}
footer {
  width: 100%;
  border-top: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: auto;
  padding-bottom: 10px;
  min-height: max-content;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background-color: rgb(9, 9, 46);
  font-size: 0.8rem;
}
footer img {
  margin-left: 0.5rem;
}
a {
  color: inherit;
  text-decoration: none;
}
.emax-title {
  color: rgb(236, 29, 50);
  text-decoration: none;
}
.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}
.title {
  color: white;
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;
}
.title,
.description {
  text-align: center;
  color: white;
}
.description {
  color: white;
  line-height: 1.5;
  font-size: 1.5rem;
}
.row {
  padding-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.column {
  color: white;
}
.column:not(:last-child):after {
  content: '';
  border: 1px solid white;
  height: 40px;
  margin: 10px;
}
.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}
.logo {
  height: 1em;
}
input[type='text'] {
  width: 100%;
  padding: 10px 18px;
  margin: 6px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.header {
  color: white;
}
p {
  margin-bottom: 0px;
  display: block;
  margin-block-start: 0.5em;
  /* margin-block-end: 0.5em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
